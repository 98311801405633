<script setup lang="ts">
import type { RouteLocationNormalizedLoaded } from 'vue-router'

const supabaseSession = useSupabaseSession()
const supabaseUser = useSupabaseUser()
const route = useRoute()
const { $router } = useNuxtApp()

const userTenant = useTenantStore()
const { getTenant } = userTenant

useHead({
  title: 'Refloat platform',
  bodyAttrs: {
    class: 'bg-gray-50 font-manrope',
  },
})

//  required '@headlessui/vue' (Tailwind UI)
//  in SSR context/mode
provideHeadlessUseId(() => useId())

watchEffect(() => {
  if (supabaseUser.value && !isUserAccessingAuthProtectedRoute(route as RouteLocationNormalizedLoaded))
    $router.push({ path: '/dashboard' })

  if (!supabaseUser.value && isUserAccessingAuthProtectedRoute(route as RouteLocationNormalizedLoaded))
    $router.push({ path: '/login' })
})

function isUserAccessingAuthProtectedRoute(
  route: RouteLocationNormalizedLoaded,
) {
  return !['login', 'register'].includes(route.name as string)
}

if (supabaseSession.value)
  await getTenant()
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="css">
html,
body,
#__nuxt,
#__layout {
  @apply h-full w-full;
}
</style>
