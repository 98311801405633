import type { DBDriverError, UpdateTenant } from '@refloat/supabase'

import type { MappedTenantOfferGroupsResponse, MappedTenantResponse, Tenant, UpdateTenantOfferGroup } from '@/types'

export function tenantRepository() {
  const { $api } = useNuxtApp()
  const supabaseUser = useSupabaseUser()

  async function getTenant(): Promise<Ref<MappedTenantResponse>> {
    const payload = ref<MappedTenantResponse | null>(null)

    try {
      const response = await $api(`/tenant/${supabaseUser.value!.id}`, {
        method: 'GET',
      }) as MappedTenantResponse

      payload.value = response
    }
    catch (error) {
      payload.value = {
        data: null,
        error: error as DBDriverError,
      }
    }

    return payload as Ref<MappedTenantResponse>
  }

  async function getTenantOfferGroups(): Promise<Ref<MappedTenantOfferGroupsResponse>> {
    const payload = ref<MappedTenantOfferGroupsResponse | null>(null)

    try {
      const response = await $api(`/tenant/offer-groups`, {
        method: 'GET',
      }) as MappedTenantOfferGroupsResponse

      payload.value = response
    }
    catch (error) {
      payload.value = {
        data: null,
        error: error as DBDriverError,
      }
    }

    return payload as Ref<MappedTenantOfferGroupsResponse>
  }

  async function updateTenant(
    tenantId: Tenant['id'],
    tenantInfo: Partial<UpdateTenant>,
  ): Promise<Ref<MappedTenantResponse>> {
    const payload = ref<MappedTenantResponse | null>(null)

    try {
      const response = await $api(`/tenant/${tenantId}`, {
        method: 'PATCH',
        body: tenantInfo,
      }) as MappedTenantResponse

      payload.value = response
    }
    catch (error) {
      payload.value = {
        data: null,
        error: error as DBDriverError,
      }
    }

    return payload as Ref<MappedTenantResponse>
  }

  async function setTenantOfferGroup(
    tenantId: Tenant['id'],
    { tenant_offer_group_id }: UpdateTenantOfferGroup,
  ) {
    const payload = ref<MappedTenantResponse | null>(null)

    try {
      const response = await $api(`/tenant/${tenantId}/offer-group`, {
        method: 'PATCH',
        body: {
          offerGroup: tenant_offer_group_id,
        },
      }) as MappedTenantResponse

      payload.value = response
    }
    catch (error) {
      payload.value = {
        data: null,
        error: error as DBDriverError,
      }
    }

    return payload as Ref<MappedTenantResponse>
  }

  return {
    getTenant,
    updateTenant,
    getTenantOfferGroups,
    setTenantOfferGroup,
  }
};
