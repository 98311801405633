import { defineStore } from 'pinia'

import type { TenantHeardAboutUs, TenantOfferGroupsExtendedTableSchema, UpdateTenant } from '@refloat/supabase'
import type { Tenant, UpdateTenantOfferGroup } from '@/types'
import { tenantRepository } from '@/repositories'

export const useTenantStore = defineStore('tenant', () => {
  const supabaseUser = useSupabaseUser()
  const userActiveTenant = ref<Tenant | null>(null)
  const tenantOfferGroups = ref<TenantOfferGroupsExtendedTableSchema[] | null>(null)

  const {
    getTenant: getTenantRepository,
    updateTenant: updateTenantRepository,
    getTenantOfferGroups: getTenantOfferGroupsRepository,
    setTenantOfferGroup: updateTenantOfferGroupRepository,
  } = tenantRepository()

  async function getTenant() {
    if (supabaseUser.value?.id) {
      const { data, error } = (await getTenantRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to retrieve tenant for the user with an id ${supabaseUser.value!.id}: ${error?.message}`,
        )
      }
      if (data) {
        userActiveTenant.value = data
        return userActiveTenant.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: User is not logged in')
    }
  }

  async function getTenantOfferGroups() {
    if (supabaseUser.value?.id) {
      const { data, error } = (await getTenantOfferGroupsRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to retrieve tenant offer groups: ${error?.message}`,
        )
      }
      if (data) {
        tenantOfferGroups.value = data
        return tenantOfferGroups.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: User is not logged in')
    }
  }

  async function updateTenantInfo(tenantInfo: Partial<UpdateTenant> & TenantHeardAboutUs) {
    if (userActiveTenant.value?.id) {
      const { data, error } = (await updateTenantRepository(userActiveTenant.value!.id, tenantInfo)).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to update tenant with an id ${userActiveTenant.value!.id}: ${error?.message}`,
        )
      }
      if (data) {
        userActiveTenant.value = data
        return userActiveTenant.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  async function updateTenantOfferGroup(tenantOfferGroup: UpdateTenantOfferGroup) {
    if (userActiveTenant.value?.id) {
      const { data, error } = (
        await updateTenantOfferGroupRepository(
          userActiveTenant.value!.id,
          tenantOfferGroup,
        )
      ).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to update tenant offer group | tenant: ${userActiveTenant.value!.id}, offer group: ${tenantOfferGroup.tenant_offer_group_id} | error: ${error?.message}`,
        )
      }
      if (data) {
        userActiveTenant.value = data
        return userActiveTenant.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  return {
    getTenant,
    updateTenantInfo,
    getTenantOfferGroups,
    updateTenantOfferGroup,
    userActiveTenant,
    tenantOfferGroups,
  }
})
