export default defineNuxtPlugin(async (nuxtApp) => {
  const session = useSupabaseSession()
  const config = useRuntimeConfig()
  const publicProxy = config.public.refloatApiUrl

  const api = $fetch.create({
    baseURL: `${publicProxy}/api`,
    onRequest: (request) => {
      request.options.headers = {
        Authorization: `Bearer ${session.value?.access_token}`,
      }
    },
  })

  nuxtApp.vueApp.provide('api', api)

  return {
    provide: {
      api,
    },
  }
})
