export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"appDescription"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"name":"theme-color","media":"(prefers-color-scheme: light)","content":"white"},{"name":"theme-color","media":"(prefers-color-scheme: dark)","content":"#222222"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/favicon.png","sizes":"any"},{"rel":"apple-touch-icon","href":"/favicon.png"}],"style":[],"script":[{"src":"https://static.app.vizzly.co/embedded/0.12.15/bundle.js"}],"noscript":[],"viewport":"width=device-width,initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false