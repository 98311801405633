import * as Sentry from '@sentry/vue'

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!import.meta.client)
    return

  const { replayIntegration } = await import('@sentry/vue')
  Sentry.addIntegration(
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  )
}

function getSentryIntegrations() {
  if (!import.meta.client)
    return []

  const router = useRouter()
  const browserTracing = Sentry.browserTracingIntegration({
    router,
  })
  const vueIntegration = Sentry.vueIntegration({
    trackComponents: true,
  })

  return [browserTracing, vueIntegration]
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp

    const config = useRuntimeConfig()

    Sentry.init({
      app: vueApp,
      dsn: config.public.sentryPortalDsn ?? null,
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // This sets the sample rate. We might use this to be 100% while
      // in development and sample at a lower rate in production
      // ie. Capture Replay for 10% of all sessions
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      // ie. Capture Replay for 100% of sessions with an error
      replaysOnErrorSampleRate: 1.0,

    })

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations()
  },
})
